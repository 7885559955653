import React from 'react'
import './Graphic.css'
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next"
const Graphic = () => {
const { t } = useTranslation();

   
  return (
    
    <>
    
    <div className='container-design'>

    <section className="hero d-flex align-items-center py-5">
      <div className="container text-center text-white">
        <div className="row">
          <div className="col-md-8 mx-auto" style={{marginBottom:'2rem'}}>
            <p  style={{letterSpacing:'6px', textTransform:'uppercase', marginTop:'5rem'}}className='title-small' >{t("p-design")}</p>
            <h1 className="display-4 mb-4 text-title-dev" >{t("title-design-hero")}</h1>
            <p className="lead mb-4" >{t("subtitle-design-hero")}</p>
          </div>
        </div>
      </div>
      
    </section>

    <div class="custom-shape-divider-bottom-1707696816">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill"></path>
    </svg>
    </div>
    </div>

    


    <section className="py-5" style={{background:'#FFFFFF'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src="../img/web-design1.webp" alt="Graphic Design" className="img-fluid img-border" />
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <h2 className="mb-4">{t("title-section-design")}</h2>
              <p className="lead">{t("text-design")}</p>
             <Link to='/contact' target='_parent'> <button className="btn btn-primary">{t("consult")}</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5" style={{background:'#FFFFFF'}}>
      <div className="container">
        <div className="row">
          
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <h2 className="mb-4">{t("title-2-design")}</h2>
              <p className="lead">{t("text-design2")}</p>
             <Link to='/contact' target='_parent'> <button className="btn btn-primary">{t("consult")}</button></Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="../img/web-per.webp" alt="Graphic Design" className="img-fluid img-border" />
          </div>
        </div>
      </div>
    </section>










 

  <div className="container py-5">
  <div class="text-center">
      <h3 style={{color: '#fff'}}>{t("planes")}</h3>
        <div class="line"></div>
        <p class="mt-2" style={{color:'#fff'}}>{t("planes-sub")}</p>
        </div>
    <div className="row text-center">
      {/* CARD 1 */}
      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-4'>{t("price-design")}</h1>
          <h2 className='h1 fw-bold'>$40,000<span className='text-small font-weight-normal ml-2'></span></h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design1")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design2")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design3")}
            </li>
          </ul>

        <Link to='/contact' target='_parent'>  <a href='#' className='btn btn-primary btn-block p-2 shadow rounded-pill'>
        {t("consult")}</a></Link>
        </div>
      </div>
{/* CARD 2 */}

      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-4'>Pro</h1>
          <h2 className='h1 fw-bold'>$80,000<span className='text-small font-weight-normal ml-2'></span></h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design4")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design5")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design6")}
            </li>
            <li className='mb-3 '>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("design7")}
            </li>
          </ul>

        <Link to='/contact' target='_parent'>  <a href='#' className='btn btn-primary btn-block p-2 shadow rounded-pill'>
        {t("consult")}</a></Link>
        </div>
      </div>

{/* CARD 3 */}

      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-4'>{t("price-design2")}</h1>
          <h2 className='h1 fw-bold'>$140,000<span className='text-small font-weight-normal ml-2'></span></h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design8")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design9")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design10")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design11")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("design12")}
            </li>

          </ul>

         <Link to='/contact' target='_parent'> <a href='#' className='btn btn-primary btn-block p-2 shadow rounded-pill'>
            {t("consult")} </a></Link>
        </div>
      </div>
    </div>
    </div>  
    </>
  )
}

export default Graphic