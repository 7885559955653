import React from 'react'
import { Routes, Route } from 'react-router';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import Graphic from './components/Graphic';
import Projects from './components/Projects';
const Rout = () => {
  return (
    <>
    <Routes>
        <Route path='/' exact element={<Home />}/>
        <Route path='/contact' exact element={<Contact />}/>
        <Route path='/services' exact element={<Services />}/>
        <Route path='/graphic' exact element={<Graphic />}/>
        <Route path='/projects' exact element={<Projects />}/>
    </Routes>
    </>
  )
}

export default Rout