import React, { useState } from 'react'
import './Projects.css'
import img1 from './imgs/projectone.png'

import img3 from './imgs/project2.png'

import { IoClose } from "react-icons/io5";

const Projects = () => {

  let data = [
    {
      id: 1,
      imgSrc: img1,
    },
    {
      id: 2,
      imgSrc: img3,
    },
    {
      id: 2,
      imgSrc: img3,
    }
   
   
    
  ]

  const [model, setModel] = useState(false);
  const [tempingSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
      setModel(true);
    
  }

  return (
    <>
        
<div className={model? "model open" : "model"}>
  <img src={tempingSrc} alt='riej'/>
  <IoClose onClick={() => setModel(false)}/>
</div>
    <div className='gallery'>
        {data.map((item, index) => {
            return (
              <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}> 
              <img src={item.imgSrc} alt={index}  style={{width:'100%'}}/>
              </div>
            )
        })}

    </div>
    </>
  )
}

export default Projects