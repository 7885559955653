import './Contact.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next"
const Contact = () => {
  const { t } = useTranslation();
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kd7f91d', 'template_vs9bjal', form.current, 'b43_-CYbtBcMytEd3')
      .then((result) => {
      }, (error) => {
          console.log(error.text);
      });
      Swal.fire({
           title: 'Éxito',
           text: '!Mensaje Enviado!',
           icon: 'success',
           confirmButtonText: 'OK'
         });
      limpiarFormulario();
  };

  function limpiarFormulario() {
    document.getElementById("miFormulario").reset();
  }
  return (
    <>
  
    <div className='title-contact'>
      <h1>{t("title-contact")}</h1>
      <p>{t("subtitle-contact")}</p>
    </div>
    <div className="form-area">
        <div className="container">
          <div className="row single-form g-0">
            <div className="col-sm-12 col-lg-6">
              <div className="left">
              <span className='span-h'><img src='../img/personal-email.svg' className='image-email'></img>

              </span>
              </div>
            </div>
            
            <div className="col-sm-12 col-lg-6">
              <div className="right">
                <form ref={form} onSubmit={sendEmail} id="miFormulario">
                  <div class="mb-3">
                    <h1 style={{marginBottom:'5rem', color:'#000'}}>Empecemos a hablar!</h1>
                    <label for="exampleInputEmail1" class="form-label">{t("name")}</label>
                    <input type="text" name='user_name' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  required/>
                    <div id="emailHelp" class="form-text"></div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Email</label>
                    <input type="email" name='user_email' class="form-control" id="exampleInputPassword1"  required/>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">{t("mensaje")}</label>
                    <textarea name='message' class="form-control" id="exampleInputPassword1"  required/>
                  </div>
                  
                  <button type="submit" value='Send' class="btn btn-primary">{t("submit")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Contact