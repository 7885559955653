import React from 'react'
import './Footer.css'
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import {useTranslation} from "react-i18next"
const Footer = () => {
   
 
    const { t } = useTranslation();
  
  return (
    <>
    <footer>
    
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-sm-6'>
                    <div className="single-box">
                        <img src='../img/logo-pixel-orbit.png' alt='logo'></img><span className='title-span'>PixelStudio</span>
                        
                        <p>{t("desc-footer")}  </p>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="single-box">
                        <h2>{t("web-design")}</h2>
                        <hr style={{width:'50%', color:'#fff'}}></hr>
                        <ul>
                            <Link to='/services' target='_parent' style={{color: '#fff'}}><li>One Page</li></Link>
                           <Link to='/services' target='_parent' style={{color: '#fff'}}><li>Landing Page</li></Link>
                            <Link to='/services' target='_parent' style={{color: '#fff'}}><li>Web Institucional</li></Link>
                            <Link to='/services' target='_parent' style={{color: '#fff'}}><li>E-Commerce</li></Link>
                            <Link to='/services' target='_parent' style={{color: '#fff'}}><li>Hoteles y turismo</li></Link>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="single-box">
                        <h2>{t("title-link-two")}</h2>
                        <hr style={{width:'50%', color:'#fff'}}></hr>
                        <ul>
                        <Link to='/graphic' target='_parent' style={{color: '#fff'}}> <li>{t("link-one")}</li></Link>
                        <Link to='/graphic' target='_parent' style={{color: '#fff'}}><li>{t("link-two")}</li></Link>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="single-box">
                        <h2>Contactanos</h2>
                        <hr style={{width:'50%', color:'#fff'}}></hr>
                        <p>+54 9 116018-5717</p>
                        <p>hola@pixelstudioweb.com</p>
                        <hr style={{width:'50%', color:'#fff'}}></hr>
                        <h2>{t("follow")}</h2>
                        <p className='socials'>
                            <i className='fa fa-facebook'><FaFacebook /></i>
                           <a href='https://www.instagram.com/pixelstudiok/' target='_blank' without rel="noreferrer"><i className='fa fa-instagram' style={{color: '#fff'}}><FaInstagram /></i></a>
                            <i className='fa fa-facebook'><FaLinkedin /></i>
                        </p>
                    </div>
                    
                </div>
                
            </div>
            <hr className='mb-4' style={{color:'#9b9b9b', marginTop:'5rem'}}/>

                    <div className='text-center mb-2'>
                        <p style={{color:'#fff', fontSize:'14px'}}>
                        &copy;{new Date().getFullYear()} Copyright {t("reserved")} |<Link to=''><span> {t("conditions")}</span></Link>
                        </p>
                    </div>
        </div>
    </footer>
    </>
  )
}

export default Footer