import React, { useEffect, useState } from 'react'
import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rout from './rout';
import { BrowserRouter } from 'react-router-dom';
import Btn from './components/Btn';
import Footer from './components/Footer';
import { Loader } from './components/loader/Loader';
import Gotop from './components/Gotop';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
     await new Promise(resolve => setTimeout(resolve,));
     setIsLoading(false);
    };

    fetchData();
  }, []);
  return (
    isLoading ? (
      <Loader />
    ) : (
    <>
    <BrowserRouter>
      <Navbar/>
      <Btn />
      <Gotop />
      <Rout />
      <Footer />
      </BrowserRouter>
      
    </>
    )
  );
}

export default App;
