import React, {useEffect}from 'react'
import './Services.css'
import {useTranslation} from "react-i18next"
import backgroundImage from './city2.jpg'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom'
const Services = () => {
const { t } = useTranslation();
  
useEffect(() => {
  AOS.init({duration:800,
  once:true
  });
  
}, []);
  return (
    <>
    <section className="hero d-flex align-items-center py-5" style={{ background: '#000'}}>
      <div className="container text-center text-white">
        <div className="row">
          <div className="col-md-8 mx-auto" style={{marginBottom:'2rem'}}>
            <p  style={{letterSpacing:'6px', textTransform:'uppercase', marginTop:'5rem'}} data-aos="fade-right" className='title-small' >{t("title-small")}</p>
            <h1 className="display-4 mb-4 text-title-dev" data-aos="fade-left">{t("title-important")} </h1>
            <p className="lead mb-4" data-aos="fade-right">{t("subtitle-web")}</p>
          </div>
        </div>
      </div>
    </section>

    









{/* SECTION WHY IS IMPORTANT A WEB SITE */}

<section className="py-5 position-relative img-fluid"  style={{ background: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,10)), url(' + backgroundImage + ')', backgroundSize: 'cover', backgroundPosition: 'center', display:'block', maxWidth: '100%', height:'auto' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 text-white">
            <h2 className="display-4 mb-4" data-aos="fade-right">{t("title-web")}</h2>
            <p className="lead" data-aos="fade-right">
            {t("text-web")}
            </p>
            <p data-aos="fade-right">
            {t("text-web2")}
            </p>
          </div>
          <div className="col-lg-6">
            <img
              src={backgroundImage}
              className="img-fluid rounded shadow-lg"
              alt="Imagen de ejemplo"
            / >
          </div>
        </div>
      </div>
    </section>


{/* SECTION WHY IS IMPORTANT A WEB SITE */}




<div className='container-dev'>
    <div class="col text-center">
      <h1 class="display-4 services">{t("services-us")}</h1>
    </div>
    <div class="contenedor-service dos-columnas">
        <article class="entrada-blog-service" >
            <h1>{t("title-services")}</h1>
            <img src="../img/web-per.webp" alt='img'/>
            <p>{t("sub-service-one")}</p>

        </article>
        <article class="entrada-blog-service" >
            <h1>{t("title-services-two")}</h1>
            <img src="../img/e-commerce.webp" alt='img'/>
            <p>{t("sub-service-two")}</p>
        </article>

        <article class="entrada-blog-service" >
            <h1>{t("title-services-tree")}</h1>
            <img src="../img/portfolio.webp" alt='img'/>
            <p>{t("sub-service-tree")}</p>
        </article>
        <article class="entrada-blog-service" >
            <h1>{t("title-services-four")}</h1>
            <img src="../img/profesional.webp" alt='img'/>
            <p>{t("sub-service-four")}</p>
        </article>
        
    </div>
</div>
      <hr style={{borderBottom:'1px solid #fff'}}></hr>






    <div className='section-two'>
      <div className='text-section'>
        {t("title-section-choose")}
      </div>

<div className='container-service'>
      <div class="card">
  <div class="card-body" >
    <img src='../img/icon-responsive.webp' alt='img'></img>
    <h5 class="card-title">{t("section-card")}</h5>
    <p class="card-text">{t("section-card-desc")}</p>

  </div>
</div>

<div class="card" > 
  <div class="card-body" >
    <img src='../img/icon-seo.webp' alt='img'></img>
    <h5 class="card-title">{t("section-card-two")}</h5>
    <p class="card-text">{t("section-card-desc-two")}</p>

  </div>
</div>

<div class="card" >
  <div class="card-body" >
    <img src='../img/icon-style.webp' alt='img'></img>
    <h5 class="card-title">{t("section-card-tree")}</h5>
    <p class="card-text">{t("section-card-desc-tree")}</p>

  </div>
</div>

</div>


<div className='container-service-two'>
<div class="card" >
  <div class="card-body" >
    <img src='../img/icon-hosting.webp' alt='img'></img>
    <h5 class="card-title">{t("section-card-four")}</h5>
    <p class="card-text">{t("section-card-desc-four")}</p>

  </div>
</div>

<div class="card">
  <div class="card-body" >
    <img src='../img/icon-cliente.webp' alt='img' className='image-se'></img>
    <h5 class="card-title">{t("section-card-five")}</h5>
    <p class="card-text">{t("section-card-desc-five")}</p>

  </div>
</div>

<div class="card" >
  <div class="card-body" >
    <img src='../img/icon-profesional.webp' alt='img'></img>
    <h5 class="card-title">{t("section-card-six")}</h5>
    <p class="card-text">{t("section-card-desc-six")}</p>

  </div>
</div>
</div>
    </div>

    
    






{/* CARD SECTION BUY */}


<hr style={{borderBottom:'1px solid #fff' , marginBottom:'3rem', marginTop:'7rem'}}></hr>
    <div className="container py-5">
  <div class="text-center">
      <h3 style={{color: '#fff', textTransform:'uppercase', fontSize:'2.5rem'}}>{t("title-price-web")}</h3>
        <div class="line"></div>
        <p class="mt-2" style={{color:'#fff', fontSize:'20px'}}>{t("sub-price-web")}</p>
        </div>
    <div className="row text-center">
      {/* CARD 1 */}
      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-2' style={{fontSize:'26px'}}>Landing Page</h1>
          <p>{t("desc-price-one")}</p>
          <h2 className='h1 fw-bold'>$0</h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("web-one")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("web-two")}
            </li>

            <hr></hr>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-three")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-four")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-five")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-six")}
            </li>
            <hr></hr>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-seven")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-nine")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-ten")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-eleven")}
            </li>

           
            <hr></hr>
          </ul>

          <Link to='/contact'className='btn btn-primary btn-block p-2 shadow rounded-pill' target='_parent'> 
            {t("consult")} </Link>
        </div>
      </div>
{/* CARD 2 */}

      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-2' style={{fontSize:'26px'}}>{t("price-two")}</h1>
          <p>{t("desc-price-two")}</p>
          <h2 className='h1 fw-bold'>$0</h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-one")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("web-twelve")}
            </li>


            <hr></hr>


            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-three")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-four")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
             {t("web-five")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-six")}
            </li>
            <hr></hr>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-seven")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-nine")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-ten")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-eleven")}
            </li>


            
            <hr></hr>
          </ul>

          <Link to='/contact'className='btn btn-primary btn-block p-2 shadow rounded-pill' target='_parent'> 
            {t("consult")}</Link>
        </div>
      </div>

{/* CARD 3 */}

      <div className="col-lg-4 mb-5 mb-lg-0">
        <div className="bg-white p-5 rounded-lg shadow">
          <h1 className='h5 text-uppercase fw-bold mb-2' style={{fontSize:'26px'}}>{t("price-three")}</h1>
          <p>{t("Vende tus productos en linea")}</p>
          <h2 className='h1 fw-bold'>$0</h2>

          <div className='custom-seperator my-4 mx-auto'></div>

          <ul className='list-unstyled my-5 text-small text-left'>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("web-one")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
            {t("web-twelve")}
            </li>

            <hr></hr>

            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-three")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-four")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-five")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-six")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-thirteen")}
            </li>


            <hr></hr>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-seven")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-nine")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
              {t("web-ten")}
            </li>
            <li className='mb-3'>
            <FaCheck className='fa fa-check mr-2 text-primary'/>
             {t("web-eleven")}
            </li>

            
            <hr></hr>
          </ul>

          <Link to='/contact'className='btn btn-primary btn-block p-2 shadow rounded-pill' target='_parent'> 
            {t("consult")}</Link>
        </div>
      </div>
    </div>
    </div>  
    </>
  )
}

export default Services