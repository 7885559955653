import React, {useEffect, useState} from 'react'
import './Home.css'
import { SlArrowDownCircle } from "react-icons/sl";
import { Link } from 'react-router-dom';
import AOS from 'aos'
import { Collapse } from 'antd';
import {useTranslation} from "react-i18next"
import { Parallax } from 'react-parallax';
import 'aos/dist/aos.css'
const {Panel} = Collapse;


const Home = () => {
  

 /* TRANSLATE */
 const {t, i18n} = useTranslation();

 const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('i18nextLng', lng);
};


 /* ACTIVE BUTTOM */
 const [activeButton, setActiveButton] = useState(
  localStorage.getItem('active-btn') === 'true'
 );

 useEffect(() => {
  localStorage.setItem('active-btn', activeButton); // Almacenar el estado en localStorage
}, [activeButton]);



 const handleButtonClick = (buttonId) => {
  setActiveButton(buttonId === activeButton ? null : buttonId);
};


  /* AOS EFFECT */
  useEffect(() => {
    AOS.init({duration:1500,
    once:true
    });
    
  }, []);


  /* LOCAL STORAGE */

  return (
    
    <>
    <Parallax strength={300} blur={{ min: -15, max: 15 }} bgImage='../img/banner_test.webp'>
    <div className='container-img-home'>
      <div className='bg-overlay'>
        <div className='container-bg'>
          <div className='container-left'>
        <div className='text' data-aos="fade-right">PixelStudio
        <p className='text-p'>{t("subtitle")}</p>
        </div>
        <div className='btn-home'>
          <div className='gap'></div>
            <div className="btns">
        <button 
        className={`second-btn ${activeButton === 'button1' ? 'active-btn' : ''}`}
        onClick={() =>handleButtonClick('button1') & changeLanguage("es")} 
        >Español
        </button>


          <button
          className={`second-btn ${activeButton === 'button2' ? 'active-btn' : ''}`}
          onClick={() => handleButtonClick('button2') & changeLanguage("en") }  
          >English
          </button>
          </div>
      </div>
      </div>
        </div>
      </div>
    </div>
      </Parallax>
    
      <section className='sub-ins'>
        
            <h1 className='title-h1-sub' data-aos="fade-up">{t("title-subsection")}</h1>
            <p className='sub-p-sub' data-aos="fade-up">{t("subtitle-subsection")}</p>
          
          
            <section className="seccion-dos-subtitulos">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="subtitulo" data-aos="fade-right">
                            <h3>{t("subtitle-section1")}</h3>
                            <p>{t("text-section1")}</p>
                        </div>
                        <div className='custom-seperator my-4 mx-auto'></div>
                    </div>
                    <div className="col-md-6">
                        <div className="subtitulo" data-aos="fade-right">
                            <h3>{t("subtitle-section2")}</h3>
                            <p>{t("text-section2")}</p>
                        </div>
                        <div className='custom-seperator my-4 mx-auto'></div>
                    </div>
                    <div className="col-md-6">
                        <div className="subtitulo" data-aos="fade-right">
                            <h3>{t("subtitle-section3")}</h3>
                            <p>{t("text-section3")}</p>
                        </div>
                        <div className='custom-seperator my-4 mx-auto'></div>
                    </div>
                    <div className="col-md-6">
                        <div className="subtitulo" data-aos="fade-right">
                            <h3>{t("subtitle-section4")}</h3>
                            <p>{t("text-section4")}</p>
                        </div>
                        <div className='custom-seperator my-4 mx-auto'></div>
                    </div>
                </div>
            </div>
            
        </section>
        
        </section>
        
    <section className='subsection-text'>
      <div className='header_info'>
        
        
              <h1 className='text-effect' data-aos="fade-up">{t("title-section")}  <br></br></h1>
              <p className="parrafo-text" data-aos="fade-right">{t("subtitle-section-one")}</p>
              <p className='parrafo-text' data-aos="fade-right"> {t("subtitle-section-two")}</p>
              <p className='parrafo-text' data-aos="fade-right">{t("subtitle-section-tree")}</p>
        
      </div>
      
      <div className='arrow'>
        <SlArrowDownCircle />
      </div>
      
    </section>






    <h1 className='title-home'>{t("services-us")} </h1>
  
    
    <div class="contenedor tres-columnas">
    
        <article class="entrada-blog">
        <div className='image-container'>
            <img src="../img/code.webp" className='image' alt='img'/>
            <Link to='/services' target='_parent'>
                <div className='overlay'>
                  <span className='ellipsis'>...</span>
                </div>
                </Link>
            </div>
            <h3>{t("web-design")}</h3>
            <Link to='/services' target='_parent'>
              <button class="learn-more">
              <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
              </span>
              <span class="button-text" target='_parent'>{t("see-more")}</span>
            </button>
            </Link>
        </article>

        <article class="entrada-blog">
              <div className='image-container'>
              <img src="../img/web-design1.jpg" className='image' alt='img'/>
              <Link to='/graphic' target='_parent'>
              <div className='overlay'>
                <span className='ellipsis'>...</span>
              </div>
              </Link>
              </div>
            <h3>{t("network-management")}</h3>
            <Link to='/services' target='_parent'>
            <button class="learn-more">
              <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
              </span>
              <span class="button-text" target='_parent'>{t("see-more")}</span>
            </button>
            </Link>
        </article>
        <article class="entrada-blog">
        <div className='image-container'>
            <img src="../img/e-commerce.webp" className='image' alt='img'/>
            <Link to='/services' target='_parent'>
            <div className='overlay'>
                <span className='ellipsis'>...</span>
              </div>
              </Link>
              </div>
            <h3>E-Commerce</h3>
            <Link to='/services' target='_parent' style={{background:'none'}}>
            <button class="learn-more">
              <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
              </span>
              <span class="button-text" target='_parent'>{t("see-more")}</span>
            </button>
            </Link>
        </article>
    </div>


    <section className='section-design'>
    <div className="block faqPage">
    <h2 className='faq-asw'>{t("freq")}</h2>
      <div className='container-faq'>
        
        <Collapse className='faq-container' accordion defaultActiveKey={['0']}>
          <Panel className='panel'header={t("ask-one")} key="1">
            <p className='container-parrafo'>{t("res-one")}</p>
          </Panel>
          <Panel className='panel' header={t("ask-two")} key="2">
            <p className='container-parrafo'>{t("res-two")}</p>
          </Panel>
          <Panel className='panel' header={t("ask-four")} key="3">
            <p className='container-parrafo'>{t("res-three")}</p>
          </Panel>
          <Panel className='panel' header={t("ask-five")} key="4">
            <p className='container-parrafo'>{t("res-five")}</p>
          </Panel>
          <Panel className='panel' header={t("ask-six")} key="5">
            <p className='container-parrafo'>{t("res-six")}</p>
          </Panel>
          
        </Collapse>
        
        
      </div>
      <p class="text-center text-gray-600 textbase mt-9">{t("last-msg")}<Link to='/contact' class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline" target='_parent'>{t("last-msg-link")}</Link></p>
    </div>
    
    </section>
    </>
  
  )
}

export default Home;