import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next"
import './Navbar.css'

const Navbar = () => {
  /* TRANSLATE */
  const {t} = useTranslation();
  

 

/* EFFECT SCROLL NAVBAR */
 const [navbar, setNavbar] = useState(false);




 const changeBackground = () => {
  if (window.scrollY >= 80) {
    setNavbar(true);
  } else {
    setNavbar(false);
  }
 };

 window.addEventListener('scroll', changeBackground);
  return (
    
    <>
      <nav className={navbar ? 'navbar navbar-expand-lg navbar-lg fixed-top navbar active' : 'navbar navbar-expand-lg navbar-lg fixed-top navbar'}>
        <div class="container-fluid mx-auto" >
          <Link to='/'><img src='../img/logo-pixel-orbit.png' alt='logo' className='logo-two'></img><span className='title-span'>PixelStudio</span></Link>

          <button class="navbar-toggler navbar-dark navbar-toggler-custom shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation" >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ background: '#19191c'}}>
            <div class="offcanvas-header">
              <img src='../img/logo-pixel-orbit.png' className='logo-two' alt='logo'></img><span className='title-span'>PixelStudio</span>
              <button type="button" class="btn-close btn-close-white shadow-none fs-5" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div className="offcanvas-body">
              <ul class="navbar-nav justify-content-center flex-grow-1 pe-2">
                <li class="nav-item">
                  <Link to='/' class="nav-link custom-margin actives" aria-current="page" onClick="close()">{t("navbar")}</Link>
                </li>
                
                <hr style={{ color: '#fff' }} />
                <li class="nav-item" >
                  <Link to='/services' class="nav-link custom-margin" onClick="close()">{t("services")}</Link>
                </li>
                <hr style={{ color: '#fff' }}></hr>
                <li class="nav-item" >
                  <Link to='/graphic' class="nav-link custom-margin" onClick="close()">{t("diseño-grafico")}</Link>
                </li>

                <hr style={{ color: '#fff' }} />
                <li class="nav-item">
                  <Link to='/projects' class="nav-link custom-margin" onClick="close()">{t("about")}</Link>
                </li>
                <hr style={{ color: '#fff' }}></hr>
              </ul>

              <div className='d-flex justify-content-center align-items-center gap-3'>

                <Link to='/contact' onClick="close()" className='text-decoration-none px-5 py-1 rounded-4 text-hover'>{t("contact")}</Link>

              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar