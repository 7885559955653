import React from 'react'
import './Home.css'
import {BsWhatsapp} from 'react-icons/bs'
const Btn = () => {
 

  return (
    <>
    <a href='https://wa.me/5491160185717/?' target='_blank' className='btn_wsp'
      without rel="noreferrer" ><BsWhatsapp />
     </a>
    </>
  )
}

export default Btn