import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from "./i18n/Spanish/es.json"
import en from "./i18n/English/en.json"





i18next.use(initReactI18next).init({
  
  lng: "es",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
    detection : {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
  },  
});

const storedLanguage = localStorage.getItem('i18nextLng') || 'es';

i18next.changeLanguage(storedLanguage);

const setLanguageToLocalStorage = (lng) => {
  

  localStorage.setItem('i18nextLng', lng);
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App setLanguageToLocalStorage={setLanguageToLocalStorage} />
  </React.StrictMode>
);
